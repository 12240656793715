<template>
  <v-data-table
      :headers="headers"
      :items="tasks"
      sort-by="id"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Задачи</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              Новая задача
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="editedItem.question"
                        label="Вопрос"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="editedItem.answer"
                        label="Ответ"
                    ></v-text-field>
                    <v-text-field
                        v-model="editedItem.cost"
                        type="number"
                        label="Очки"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Отмена
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Вы уверены, что хотите удалить задачу?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Удалить</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.upload="{ item }">
      <UploadFileRow :id="item.id"/>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mx-2"
          :color="item.isFileExists ? `green` : `gray`"
          :disabled="!item.isFileExists"
          @click="play(item.fullFileUrl)"
      >
        mdi-play
      </v-icon>
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UploadFileRow from "@/modules/tasks/UploadFileRow";

export default {
  name: "Collection",
  components: { UploadFileRow },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'id', value: 'id' },
      { text: 'Вопрос', value: 'question' },
      { text: 'Ответ', value: 'answer' },
      { text: 'Очки', value: 'cost' },
      { text: 'Загрузка', value: 'upload', sortable: false },
      { text: 'Действия', value: 'actions', sortable: false },
    ],
    editedIndex: -1,
    defaultItem: {
      id: null,
      question: '',
      answer: '',
      cost: '',
      fullFileUrl: '',
      fileExt: "mp3",
      isFileExists: false,
    },
    editedItem: {
      id: null,
      question: '',
      answer: '',
      cost: '',
      fullFileUrl: '',
      fileExt: "mp3",
      isFileExists: false,
    },
    collectionId: null,
    player: new Audio(),
    currentAudio: {},
  }),
  created () {
    this.initialize()
  },
  computed: {
    ...mapState("Tasks", ["tasks"]),
    formTitle () {
      return this.editedIndex === -1 ? 'Новая задача' : 'Редактировать'
    },
  },
  methods: {
    ...mapActions("Tasks", ["fetchTasks", "updateTask", "deleteTask", "addTask"]),
    initialize () {
      this.collectionId = this.$route.params.id;
      this.fetchTasks(this.collectionId);
    },
    play(src) {
      this.currentAudio = { src };
      this.player.src = this.currentAudio.src;
      this.player.play();
    },
    editItem (item) {
      this.editedIndex = this.tasks.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.editedIndex = this.tasks.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteTask(this.editedItem.id)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      const task = {
        ...this.editedItem,
        cost: parseInt(this.editedItem.cost)
      };
      if (this.editedIndex > -1) {
        this.updateTask(task)
      } else {
        this.addTask({
          collectionId: this.collectionId,
          task,
        })
      }
      this.close()
    },
  },
};
</script>

<style scoped></style>