<template>
  <div>
    <form @submit.prevent="submitFile">
      <v-row class="d-flex align-center justify-center">
        <v-file-input
            show-size
            label="Аудио файл"
            prepend-icon="mdi-music"
            accept=".mp3"
            @change="changeFile"
            ref="file"
        ></v-file-input>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            type="submit"
            small
            fab
        >
          <v-icon dark small>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UploadFileRow",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    file: null
  }),
  methods: {
    ...mapActions("Tasks", ["uploadFile"]),
    changeFile(event) {
      this.file = event;
    },
    submitFile () {
      this.uploadFile({ taskId: this.id, file: this.file });
    },
  },
};
</script>

<style scoped></style>